import i18next from 'i18next';
import { tableColumnTypes } from '../../../common/templates/constants';

export const DDMM = 'DD.MM.';
export const DDMMYY = 'DD.MM.YY';

export const baseDrawerBackgroungColor = '#E8ECF2';
export const baseTicketItemBackgroundColor = '#E9F3FF';

export const STEP_STATUS_ENUM = {
    OPEN: 'open',
    IN_WORK: 'inWork',
    DONE: 'done',
    DELAYED: 'delayed',
    WAITING: 'waiting',
    NOWORKPLACE: 'noWorkplace',
};

export const BASE_UNIT_ENUM = {
    KILOGRAMS: 'kilograms',
    GRAMS: 'grams',
    LITERS: 'liters',
    PIECES: 'pieces',
    METERS: 'meters',
};

export const PROCESS_TYPE_ENUM = {
    PROCUREMENT: 'procurement',
    PRODUCTION: 'production',
    LOGISTICS: 'logistics',
    ENGINEERING: 'engineering',
};

export const PROCESS_TYPE_COLOR_ENUM = {
    [PROCESS_TYPE_ENUM.ENGINEERING]: '#6680C5',
    [PROCESS_TYPE_ENUM.PROCUREMENT]: '#8D46B0',
    [PROCESS_TYPE_ENUM.PRODUCTION]: '#58B47F',
};

export const PROCESS_TYPE_OPTIONS = [
    { title: i18next.t('process-type.procurement'), value: PROCESS_TYPE_ENUM.PROCUREMENT },
    { title: i18next.t('process-type.production'), value: PROCESS_TYPE_ENUM.PRODUCTION },
    { title: i18next.t('process-type.engineering'), value: PROCESS_TYPE_ENUM.ENGINEERING },
];

export const WORKPLACE_TYPE_ENUM = {
    SITE: 'site',
    BUILDING: 'building',
    ROOM: 'room',
    AREA: 'area',
    PRODUCTION_LINE: 'productionLine',
};

export const ProgressStepStatusColors = {
    [STEP_STATUS_ENUM.NOWORKPLACE]: '#F5325C',
    [STEP_STATUS_ENUM.OPEN]: '#B5BECC',
    [STEP_STATUS_ENUM.IN_WORK]: '#0091EA',
    [STEP_STATUS_ENUM.DONE]: '#00C853',
    [STEP_STATUS_ENUM.DELAYED]: '#FF6D00',
};

export const ProgressStepStatusLabel = {
    [STEP_STATUS_ENUM.NOWORKPLACE]: i18next.t('progress-projects.status.noWorkplace'),
    [STEP_STATUS_ENUM.OPEN]: i18next.t('progress-projects.status.open'),
    [STEP_STATUS_ENUM.IN_WORK]: i18next.t('progress-projects.status.inWork'),
    [STEP_STATUS_ENUM.DONE]: i18next.t('progress-projects.status.done'),
    [STEP_STATUS_ENUM.DELAYED]: i18next.t('progress-projects.status.delayed'),
};

export const LabelToProgressStepStatus = {
    [i18next.t('progress-projects.status.open')]: STEP_STATUS_ENUM.OPEN,
    [i18next.t('progress-projects.status.inWork')]: STEP_STATUS_ENUM.IN_WORK,
    [i18next.t('progress-projects.status.done')]: STEP_STATUS_ENUM.DONE,
    [i18next.t('progress-projects.status.delayed')]: STEP_STATUS_ENUM.DELAYED,
};

export const ProgressStepStatusOptions = [
    {
        title: i18next.t('progress-projects.status.open'),
        key: STEP_STATUS_ENUM.OPEN,
    },
    {
        title: i18next.t('progress-projects.status.inWork'),
        key: STEP_STATUS_ENUM.IN_WORK,
    },
    {
        title: i18next.t('progress-projects.status.done'),
        key: STEP_STATUS_ENUM.DONE,
    },
];

export const OrderQtyUnitOptions = [
    {
        title: i18next.t('materialInformation.orderQtyUnitOptions.kilograms'),
        key: BASE_UNIT_ENUM.KILOGRAMS,
    },
    {
        title: i18next.t('materialInformation.orderQtyUnitOptions.grams'),
        key: BASE_UNIT_ENUM.GRAMS,
    },
    {
        title: i18next.t('materialInformation.orderQtyUnitOptions.liters'),
        key: BASE_UNIT_ENUM.LITERS,
    },
    {
        title: i18next.t('materialInformation.orderQtyUnitOptions.pieces'),
        key: BASE_UNIT_ENUM.PIECES,
    },
    {
        title: i18next.t('materialInformation.orderQtyUnitOptions.meters'),
        key: BASE_UNIT_ENUM.METERS,
    },
];

export const BaseDataColumns = [
    {
        key: 'filename',
        title: i18next.t('attachments.table.columns.fileName'),
        columnType: tableColumnTypes.string,
        orderBy: 'filename',
    },
    {
        key: 'filetype',
        title: i18next.t('attachments.table.columns.fileType'),
        columnType: tableColumnTypes.string,
        orderBy: 'filetype',
    },
    {
        key: 'createdAt',
        title: i18next.t('attachments.table.columns.createdAt'),
        columnType: tableColumnTypes.date,
        orderBy: 'createdAt',
    },
    {
        key: 'context-menu',
        padding: 'checkbox',
        columnType: tableColumnTypes.action,
        minWidth: 50,
    },
];

export const AttachmentsTableColumns = [
    {
        key: 'filename',
        title: i18next.t('attachments.table.columns.fileName'),
        columnType: tableColumnTypes.string,
        orderBy: 'filename',
    },
    {
        key: 'filetype',
        title: i18next.t('attachments.table.columns.fileType'),
        columnType: tableColumnTypes.string,
        orderBy: 'filetype',
    },
    {
        key: 'context-menu',
        padding: 'checkbox',
        columnType: tableColumnTypes.action,
        minWidth: 50,
    },
];

export const MAX_NUMBER_OF_MEDIA = 100;
export const SECONDS_IN_HOUR = 3600;

export const workshopsStatusOptions = [
    {
        title: i18next.t('progress-workshops.filters.statusOptions.open'),
        value: STEP_STATUS_ENUM.OPEN,
    },
    {
        title: i18next.t('progress-workshops.filters.statusOptions.inWork'),
        value: STEP_STATUS_ENUM.IN_WORK,
    },
];

export const workshopsTimestampOptions = [
    {
        title: i18next.t('progress-workshops.filters.timestampOptions.today'),
        value: 1,
    },
    {
        title: i18next.t('progress-workshops.filters.timestampOptions.todayPlusOneWeek'),
        value: 7,
    },
    {
        title: i18next.t('progress-workshops.filters.timestampOptions.todayPlusTwoWeeks'),
        value: 14,
    },
];

export const productionOrderReleaseStatusOptions = [
    {
        title: 'Draft',
        value: 'draft',
    },
    {
        title: 'Pending',
        value: 'pending',
    },
    {
        title: 'Approved',
        value: 'approved',
    },
    {
        title: 'Rejected',
        value: 'rejected',
    },
];

export const baseDateFormat = 'DD.MM.YYYY';

export const TAGS_ENUM = {
    MECHANISCH: 'mechanisch',
    ELEKTRISCH: 'elektrisch',
    BESTELLUNG: 'bestellung',
};

export const TAGS_OPTIONS = [
    { value: TAGS_ENUM.ELEKTRISCH, title: i18next.t(`processTags.elektrisch`) },
    { value: TAGS_ENUM.MECHANISCH, title: i18next.t(`processTags.mechanisch`) },
];

export const PROCUREMENT_TAGS = [{ value: [TAGS_ENUM.BESTELLUNG], title: i18next.t(`processTags.bestellung`) }];

export const TAGS_COLOR_ENUM = {
    [TAGS_ENUM.ELEKTRISCH]: '#58B47F',
    [TAGS_ENUM.MECHANISCH]: '#6680C5',
    [TAGS_ENUM.BESTELLUNG]: '#8D46B0',
};

export const CREATE_STEP_MODAL_STEPS = {
    STEP_FORM: {
        index: 0,
    },
    BULK_ORDER: {
        index: 1,
    },
    PROCUREMENT_ATTACHMENTS: {
        index: 2,
    },
    PRODUCTION_ATTACHMENTS: {
        index: 1,
    },
};

export const waypointBottomOffset = '-200px';
export const defaultProjectLimit = 20;

export const noWorkplaceItem = {
    _id: null,
    workPlaceName: [
        {
            language: 'EN',
            value: 'No Workplace',
        },
        {
            language: 'DE',
            value: 'Kein Arbeitsplatz',
        },
    ],
};

export const itemsPerPage = 50;
export const defaultPage = 1;

export const defaultProductionOrderLimit = 50;

export const ticketColorByBackgroungColorCode = {
    '#8D46B0': '#FFFFFF',
    '#E9F3FF': '#042727',
    '#E1FFDE': '#042727',
};
