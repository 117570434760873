import React from 'react';

import { ReactComponent as GbFlag } from '../resources/icons/flags/gb.svg';
import { ReactComponent as DeFlag } from '../resources/icons/flags/de.svg';

import { reactAppName } from './Config';

export const appLanguage = {
    en: 'EN',
    de: 'DE',
    pt: 'PT',
    hu: 'HU',
};

export const appLanguageOptions = [
    {
        key: appLanguage.en,
        value: appLanguage.en,
        translation: 'protocol-template-builder.top-panel.language.options.en',
        icon: <GbFlag />,
    },
    {
        key: appLanguage.de,
        value: appLanguage.de,
        translation: 'protocol-template-builder.top-panel.language.options.de',
        icon: <DeFlag />,
    },
];

export const defaultTranslatorPriority = {
    [appLanguage.en]: {
        primaryLanguage: appLanguage.en,
        secondaryLanguage: appLanguage.de,
    },
    [appLanguage.de]: {
        primaryLanguage: appLanguage.de,
        secondaryLanguage: appLanguage.en,
    },
};

export const appTranslationsKeyByAppName = {
    [reactAppName.audit]: 'audit',
    [reactAppName.protocol]: 'protocol',
    [reactAppName.maintenance]: 'maintenance',
    [reactAppName.machine_maintenance]: 'machine-maintenance',
    [reactAppName.progress]: 'progress',
    [reactAppName.workbook]: 'workbook',
    [reactAppName.process]: 'process',
    [reactAppName.maintenance_standard]: 'maintenance-standard',
};
