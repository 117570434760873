import { reactAppCustomer, reactAppName } from '../constants/Config';

export const GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL || 'https://5i-test-gateway.azurewebsites.net/graphql';
export const ORGANIZATION = process.env.REACT_APP_ORGANIZATION || 'localDev';

export const AWS_S3_BUCKET_EHS_USER_FOLDER = process.env.REACT_APP_AWS_S3_BUCKET_EHS_USER_FOLDER || `users`;
export const AWS_S3_BUCKET_EHS_TICKET_FOLDER = process.env.REACT_APP_AWS_S3_BUCKET_EHS_TICKET_FOLDER || `ehsAudit/tickets`;
export const AWS_S3_BUCKET_EHS_QUESTION_FOLDER = process.env.REACT_APP_AWS_S3_BUCKET_EHS_QUESTIONS_FOLDER || `ehsAudit/questions`;
export const AWS_S3_BUCKET_MAINTENANCE_TICKET_FOLDER =
    process.env.REACT_APP_AWS_S3_BUCKET_MAINTENANCE_TICKET_FOLDER || `maintenance/tickets`;
export const AWS_S3_BUCKET_MAINTENANCE_ASSET_FOLDER = process.env.REACT_APP_AWS_S3_BUCKET_MAINTENANCE_ASSET_FOLDER || `maintenance/assets`;
export const AWS_S3_BUCKET_PROTOCOL_TEMPLATES_FOLDER =
    process.env.REACT_APP_AWS_S3_BUCKET_PROTOCOL_TEMPLATES_FOLDER || `protocols/templateImages`;
export const AWS_S3_BUCKET_INSPECTION_REPORTS_FOLDER =
    process.env.REACT_APP_AWS_S3_BUCKET_INSPECTION_REPORTS_FOLDER || `protocols/inspectionReports`;
export const AWS_S3_BUCKET_DRAWINGS_FOLDER = process.env.REACT_APP_AWS_S3_BUCKET_DRAWINGS_FOLDER || `protocols/drawings`;
export const AWS_S3_BUCKET_INSPECTION_NORMS_FOLDER =
    process.env.REACT_APP_AWS_S3_BUCKET_INSPECTION_NORMS_FOLDER || `protocols/inspectionNorms`;
export const AWS_S3_BUCKET_INSPECTION_REGULATIONS_FOLDER =
    process.env.REACT_APP_AWS_S3_BUCKET_INSPECTION_REGULATIONS_FOLDER || `protocols/inspectionRegulations`;
export const AWS_S3_BUCKET_INSPECTION_IMAGES_FOLDER =
    process.env.REACT_APP_AWS_S3_BUCKET_INSPECTION_IMAGES_FOLDER || `protocols/inspectionImages`;
export const AWS_S3_BUCKET_PROTOCOL_GT_XLS_TEMPLATES_FOLDER =
    process.env.REACT_APP_AWS_S3_BUCKET_PROTOCOL_GT_XLS_TEMPLATES_FOLDER || `protocols/GT_ExcelTemplate`;
export const AWS_S3_BUCKET_PROGRESS_MEDIA_FOLDER = process.env.AWS_S3_BUCKET_PROGRESS_MEDIA_FOLDER || `progress/medias`;

export const APP_INSIGHTS_KEY = process.env.REACT_APP_INSIGHTS_KEY || '';
export const APPLICATION_SERVER_PUBLIC_KEY = process.env.REACT_APP_APPLICATION_SERVER_PUBLIC_KEY || '';

export const APPLICATION_INSIGHTS_INVESTIGATION_ENDPOINT = 'https://westeurope-5.in.applicationinsights.azure.com/';

export const AWS_IDENTITY_POOL_ID = process.env.REACT_APP_AWS_IDENTITY_POOL_ID || 'eu-central-1:9a86f7e5-3bde-4a74-a6b7-56857725f6d5';
export const AWS_REGION = process.env.REACT_APP_AWS_REGION || 'eu-central-1';
export const AWS_USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID || 'eu-central-1_pWwoBGFLS';
export const AWS_USER_POOL_WEB_CLIENT = process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT || '1iu11hgjgr2p1scfa45ph57hp0';
export const AWS_OAUTH_DOMAIN = process.env.REACT_APP_AWS_OAUTH_DOMAIN || '5i-dev.auth.eu-central-1.amazoncognito.com';

export const PUSH_API_APP_SERVER_KEY =
    process.env.REACT_APP_PUSH_API_APP_SERVER_KEY ||
    'BKJNkT5sx0gDHMD2M0l8U55bcy002590bWFLwF8KhuM239l_6Zk4fl3WxfXvftN2Q9CGpHoRxOdnyctBXf-cDVc';
export const WEATHER_DATA_API_KEY = process.env.REACT_APP_WEATHER_DATA_API_KEY || 'f7e3bf828bfba77381bf861512cb1951';

export const AWS_AMPLIFY_DEFAULT_LANGUAGE = process.env.REACT_APP_AWS_AMPLIFY_DEFAULT_LANGUAGE || 'de';
export const APP_CUSTOMER = process.env.REACT_APP_CUSTOMER || reactAppCustomer.dev;
export const APP_NAME = process.env.NODE_ENV === 'development' ? reactAppName.progress : process.env.REACT_APP_NAME || reactAppName.audit;

export const ADAMOS_IDENTITY_PROVIDER_NAME = process.env.REACT_APP_ADAMOS_IDENTITY_PROVIDER_NAME || '';
export const AZUREAD_IDENTITY_PROVIDER_NAME = process.env.REACT_APP_AZUREAD_IDENTITY_PROVIDER_NAME || '';
