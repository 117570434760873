//https://www.npmjs.com/package/react-app-polyfill
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './utils/i18n';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './index.css';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorkerRegistration.register();
