export const isWindowExists = () => {
    return typeof window != 'undefined';
};

export const windowOpen = link => {
    if (!link.match(/^https?:\/\//i)) {
        window.open(`http://${link}`, '_blank').focus();
    } else {
        window.open(link, '_blank').focus();
    }
};