import React, { lazy } from 'react';

import { APP_NAME } from '../utils/env';
import { reactAppName } from '../constants/Config';

const AuditRouter = lazy(() => import('../components/audit/Router'));
const ProtocolRouter = lazy(() => import('../components/protocol/Router'));
const ProgressRouter = lazy(() => import('../components/progress/Router'));
const ProcessRouter = lazy(() => import('../components/process/Router'));
const MaintenanceRouter = lazy(() => import('../components/maintenance/Router'));
const MachineMaintenanceRouter = lazy(() => import('../components/machine-maintenance/Router'));
const MaintenanceStandardRouter = lazy(() => import('../components/maintenance-standard/Router'));
const WorkbookRouter = lazy(() => import('../components/workbook/Router'));

const RouterProvider = () => {
    const application = APP_NAME;

    if (application === reactAppName.audit) {
        return <AuditRouter />;
    }

    if (application === reactAppName.protocol) {
        return <ProtocolRouter />;
    }

    if (application === reactAppName.progress) {
        return <ProgressRouter />;
    }

    if (application === reactAppName.process) {
        return <ProcessRouter />;
    }

    if (application === reactAppName.maintenance) {
        return <MaintenanceRouter />;
    }

    if (application === reactAppName.machine_maintenance) {
        return <MachineMaintenanceRouter />;
    }

    if (application === reactAppName.maintenance_standard) {
        return <MaintenanceStandardRouter />;
    }

    if (application === reactAppName.workbook) {
        return <WorkbookRouter />;
    }

    return <div></div>;
};

export default RouterProvider;
